var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.design === "Chip"
    ? _c(
        "v-chip",
        { attrs: { label: "", color: _vm.color, "text-color": "white" } },
        [
          _vm.i18nLabel
            ? _c("span", [_vm._v(_vm._s(_vm.$t(_vm.i18nLabel)))])
            : _c("span", [_vm._v(_vm._s(_vm.state))]),
        ]
      )
    : _vm.design === "Text"
    ? _c("span", [
        _vm.i18nLabel
          ? _c("span", [_vm._v(_vm._s(_vm.$t(_vm.i18nLabel)))])
          : _c("span", [_vm._v(_vm._s(_vm.state))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }