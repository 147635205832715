var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _vm.brand.isBusy
        ? _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-card",
                    { attrs: { tile: "", flat: "", color: "transparent" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("brand.details.loading")) + " "
                          ),
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.brand.error
        ? _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "", flat: "", color: "transparent" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            outlined: "",
                            type: "error",
                            prominent: "",
                            border: "left",
                          },
                        },
                        [
                          _c("div", { staticClass: "text-h6" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("brand.details.errorHeader")) +
                                " "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("brand.details.errorText"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.brand.object
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "section" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "", "align-center": "" } },
                            [
                              _vm.$braendz.getBrandLogoUrl(_vm.brand.object)
                                ? _c("v-img", {
                                    attrs: {
                                      src: _vm.$braendz.getBrandLogoUrl(
                                        _vm.brand.object
                                      ),
                                      contain: "",
                                      "max-height": "30vh",
                                    },
                                  })
                                : _c("v-icon", { attrs: { size: "30vh" } }, [
                                    _vm._v("fa-light fa-image-slash"),
                                  ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "details-section" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "d-flex flex-column",
                              attrs: {
                                tile: "",
                                flat: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-registered"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("brand.details.brand"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c("BrandDetailsTextField", {
                                    attrs: {
                                      wrap: true,
                                      title: _vm.$t("brand.details.name"),
                                      value: _vm.brand.object.name,
                                    },
                                  }),
                                  _c("BrandDetailsTextField", {
                                    staticClass: "mt-3",
                                    attrs: {
                                      wrap: true,
                                      title: _vm.$t(
                                        "brand.details.description"
                                      ),
                                      value: _vm.brand.object.description,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "details-section" },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                tile: "",
                                flat: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-memo-circle-info"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("brand.details.brandInformation")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "d-flex flex-row align-content-center flex-wrap",
                                },
                                [
                                  _vm.brand.object.registrationOfficeCode
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.registrationOfficeCode"
                                          ),
                                          value:
                                            _vm.brand.object
                                              .registrationOfficeCode
                                              .description,
                                          alternative1:
                                            _vm.brand.object
                                              .registrationOfficeCode
                                              .shortDescription,
                                          alternative2:
                                            _vm.brand.object
                                              .registrationOfficeCode.key,
                                          wrap: true,
                                        },
                                      })
                                    : _vm._e(),
                                  _c("BrandDetailsTextField", {
                                    staticClass: "pr-3 pb-3",
                                    attrs: {
                                      width: _vm.defaultCellWidth,
                                      title: _vm.$t(
                                        "brand.details.applicationNumber"
                                      ),
                                      value: _vm.brand.object.applicationNumber,
                                    },
                                  }),
                                  _c("BrandDetailsTextField", {
                                    staticClass: "pr-3 pb-3",
                                    attrs: {
                                      width: _vm.defaultCellWidth,
                                      title: _vm.$t(
                                        "brand.details.registrationNumber"
                                      ),
                                      value:
                                        _vm.brand.object.registrationNumber,
                                    },
                                  }),
                                  _vm.brand.object.originBrandType
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.originBrandTypeTitle,
                                          value:
                                            _vm.brand.object.originBrandType
                                              .description,
                                          alternative1:
                                            _vm.brand.object.originBrandType
                                              .shortDescription,
                                          alternative2:
                                            _vm.brand.object.originBrandType
                                              .key,
                                          wrap: true,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.brand.object.brandState
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.brandState"
                                          ),
                                          value:
                                            _vm.brand.object.brandState
                                              .description,
                                          alternative1:
                                            _vm.brand.object.brandState
                                              .shortDescription,
                                          alternative2:
                                            _vm.brand.object.brandState.key,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.brand.object.kindMark
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.kindMark"
                                          ),
                                          value:
                                            _vm.brand.object.kindMark
                                              .description,
                                          alternative1:
                                            _vm.brand.object.kindMark
                                              .shortDescription,
                                          alternative2:
                                            _vm.brand.object.kindMark.key,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.brand.object.brandType
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.brandType"
                                          ),
                                          value:
                                            _vm.brand.object.brandType
                                              .description,
                                          alternative1:
                                            _vm.brand.object.brandType
                                              .shortDescription,
                                          alternative2:
                                            _vm.brand.object.brandType.key,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "details-section" },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                tile: "",
                                flat: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-calendar-lines"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("brand.details.brandDates"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "d-flex flex-row align-content-center flex-wrap",
                                },
                                [
                                  _c("BrandDetailsTextField", {
                                    staticClass: "pr-3 pb-3",
                                    attrs: {
                                      width: _vm.defaultCellWidth,
                                      title: _vm.$t(
                                        "brand.details.applicationDate"
                                      ),
                                      value: _vm.toNullableDate(
                                        _vm.brand.object.applicationDate
                                      ),
                                    },
                                  }),
                                  _c("BrandDetailsTextField", {
                                    staticClass: "pr-3 pb-3",
                                    attrs: {
                                      width: _vm.defaultCellWidth,
                                      title: _vm.$t(
                                        "brand.details.registrationDate"
                                      ),
                                      value: _vm.toNullableDate(
                                        _vm.brand.object.registrationDate
                                      ),
                                    },
                                  }),
                                  _c("BrandDetailsTextField", {
                                    staticClass: "pr-3 pb-3",
                                    attrs: {
                                      width: _vm.defaultCellWidth,
                                      title: _vm.$t(
                                        "brand.details.oppositionPeriodStartDate"
                                      ),
                                      value: _vm.toNullableDate(
                                        _vm.brand.object
                                          .oppositionPeriodStartDate
                                      ),
                                    },
                                  }),
                                  _c("BrandDetailsTextField", {
                                    staticClass: "pr-3 pb-3",
                                    attrs: {
                                      width: _vm.defaultCellWidth,
                                      title: _vm.$t(
                                        "brand.details.oppositionPeriodEndDate"
                                      ),
                                      value: _vm.toNullableDate(
                                        _vm.brand.object.oppositionPeriodEndDate
                                      ),
                                    },
                                  }),
                                  _c("BrandDetailsTextField", {
                                    staticClass: "pr-3 pb-3",
                                    attrs: {
                                      width: _vm.defaultCellWidth,
                                      title: _vm.$t(
                                        "brand.details.publicationDate"
                                      ),
                                      value: _vm.toNullableDate(
                                        _vm.brand.object.publicationDate
                                      ),
                                    },
                                  }),
                                  _c("BrandDetailsTextField", {
                                    staticClass: "pr-3 pb-3",
                                    attrs: {
                                      width: _vm.defaultCellWidth,
                                      title: _vm.$t("brand.details.expiryDate"),
                                      value: _vm.toNullableDate(
                                        _vm.brand.object.expiryDate
                                      ),
                                    },
                                  }),
                                  _vm.brand.object.terminationDate
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.terminationDate"
                                          ),
                                          value: _vm.toNullableDate(
                                            _vm.brand.object.terminationDate
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.brand.object.exhibitionDate ||
                                  _vm.brand.object.exhibitionName
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.exhibitionInfo"
                                          ),
                                          value: _vm.toNullableDate(
                                            _vm.brand.object.exhibitionDate
                                          ),
                                          value2:
                                            _vm.brand.object.exhibitionName,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.brand.object.asItIsProtectionIndicator
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.asItIsProtectionIndicator"
                                          ),
                                          value:
                                            _vm.brand.object
                                              .asItIsProtectionIndicator,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.brand.object.tradeDistinctivenessIndicator
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.tradeDistinctivenessText"
                                          ),
                                          value:
                                            _vm.brand.object
                                              .tradeDistinctivenessText,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "details-section" },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                tile: "",
                                flat: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-address-card"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("brand.details.brandAddresses")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "d-flex flex-row align-content-start flex-wrap",
                                },
                                [
                                  _vm.brand.object.ownerAddress
                                    ? _c("BrandDetailsAddressField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: 2 * _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.ownerAddress"
                                          ),
                                          value: _vm.brand.object.ownerAddress,
                                          loginRequired: true,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.brand.object.representativeAddress
                                    ? _c("BrandDetailsAddressField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: 2 * _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.representativeAddress"
                                          ),
                                          value:
                                            _vm.brand.object
                                              .representativeAddress,
                                          loginRequired: true,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.brand.object.correspondenceAddress
                                    ? _c("BrandDetailsAddressField", {
                                        staticClass: "pr-3 pb-3",
                                        attrs: {
                                          width: 2 * _vm.defaultCellWidth,
                                          title: _vm.$t(
                                            "brand.details.correspondenceAddress"
                                          ),
                                          value:
                                            _vm.brand.object
                                              .correspondenceAddress,
                                          loginRequired: true,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "details-section" },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                tile: "",
                                flat: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-grid-2"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "brand.details.brandClassification"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c(
                                    "BrandDetailsTextField",
                                    {
                                      staticClass: "text-wrap pb-3",
                                      attrs: {
                                        title: _vm.$t(
                                          "brand.details.niceClasses"
                                        ),
                                        titleTop: "",
                                      },
                                    },
                                    [
                                      _vm.brand.object.niceClasses
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row",
                                              },
                                              _vm._l(
                                                _vm.brand.object.niceClasses,
                                                function (niceClass, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      niceClass.description
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-chip",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1 mb-1",
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                "v-chip",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "body-1",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      niceClass.key
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    niceClass.description
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "mr-1 mb-1",
                                                              attrs: {
                                                                small: "",
                                                                outlined: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "body-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      niceClass.key
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "BrandDetailsTextField",
                                    {
                                      staticClass: "text-wrap pb-3",
                                      attrs: {
                                        title: _vm.$t(
                                          "brand.details.viennaClasses"
                                        ),
                                        titleTop: "",
                                      },
                                    },
                                    [
                                      _vm.brand.object.viennaClasses
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row",
                                              },
                                              _vm._l(
                                                _vm.brand.object.viennaClasses,
                                                function (viennaClass, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      viennaClass.description
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-chip",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1 mb-1",
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                "v-chip",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "body-1",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      viennaClass.key
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    viennaClass.description
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "mr-1 mb-1",
                                                              attrs: {
                                                                small: "",
                                                                outlined: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "body-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      viennaClass.key
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm.brand.object.imageColor
                                    ? _c("BrandDetailsTextField", {
                                        staticClass: "pb-3",
                                        attrs: {
                                          titleTop: "",
                                          title: _vm.$t(
                                            "brand.details.imageColor"
                                          ),
                                          value: _vm.brand.object.imageColor,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "BrandDetailsTextField",
                                    {
                                      staticClass: "text-wrap pb-3",
                                      attrs: {
                                        title: _vm.$t(
                                          "brand.details.classifiedGoodsAndServices"
                                        ),
                                        titleTop: "",
                                      },
                                    },
                                    [
                                      _vm.classifiedGoodsAndServices &&
                                      _vm.classifiedGoodsAndServices.length > 0
                                        ? [
                                            _c(
                                              "v-list",
                                              {
                                                attrs: { color: "transparent" },
                                              },
                                              _vm._l(
                                                _vm.classifiedGoodsAndServices,
                                                function (
                                                  goodOrService,
                                                  index
                                                ) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: index,
                                                      staticClass: "mb-2",
                                                      staticStyle: {
                                                        "min-height": "35px",
                                                      },
                                                    },
                                                    [
                                                      goodOrService.niceClass
                                                        ? _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              staticClass:
                                                                "align-self-start my-0 py-0",
                                                              attrs: {
                                                                tile: "",
                                                                height: 35,
                                                              },
                                                            },
                                                            [
                                                              goodOrService
                                                                .niceClass
                                                                .description
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  goodOrService.niceClass
                                                                                    ? _c(
                                                                                        "v-chip",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              staticClass:
                                                                                                "align-self-start",
                                                                                              attrs:
                                                                                                {
                                                                                                  small:
                                                                                                    "",
                                                                                                  outlined:
                                                                                                    "",
                                                                                                },
                                                                                            },
                                                                                            "v-chip",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "body-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  goodOrService
                                                                                                    .niceClass
                                                                                                    .key
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              goodOrService
                                                                                .niceClass
                                                                                .description
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "align-self-start",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "body-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              goodOrService
                                                                                .niceClass
                                                                                .key
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-list-item-content",
                                                        {
                                                          staticClass:
                                                            "my-0 py-0 align-self-start",
                                                        },
                                                        [
                                                          goodOrService.description
                                                            ? _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "wrap-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      goodOrService.description
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "font-italic text--disabled",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "brand.details.goodsOrServiceDescriptionNotAvailable"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "BrandDetailsTextField",
                                    {
                                      staticClass: "text-wrap pb-3",
                                      attrs: {
                                        title: _vm.$t(
                                          "brand.details.unclassifiedGoodsAndServices"
                                        ),
                                        titleTop: "",
                                      },
                                    },
                                    [
                                      _vm.unclassifiedGoodsAndServices &&
                                      _vm.unclassifiedGoodsAndServices.length >
                                        0
                                        ? [
                                            _c(
                                              "v-list",
                                              {
                                                attrs: { color: "transparent" },
                                              },
                                              _vm._l(
                                                _vm.unclassifiedGoodsAndServices,
                                                function (
                                                  goodOrService,
                                                  index
                                                ) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: index,
                                                      staticStyle: {
                                                        "min-height": "35px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        {
                                                          staticClass:
                                                            "my-0 py-1 align-self-start",
                                                        },
                                                        [
                                                          goodOrService.description
                                                            ? _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "wrap-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        goodOrService.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "font-italic text--disabled",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "brand.details.goodsOrServiceDescriptionNotAvailable"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "details-section" },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                tile: "",
                                flat: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-timeline"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "brand.details.processInformation"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center",
                                },
                                [
                                  _c("BrandDetailsProcessTimeline", {
                                    attrs: {
                                      processes: _vm.brand.object.processes,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }