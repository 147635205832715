var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.processes && _vm.processes.length > 0
    ? _c(
        "v-timeline",
        { attrs: { dense: "", "align-top": "" } },
        _vm._l(_vm.processes, function (process, index) {
          return _c(
            "v-timeline-item",
            { key: index, attrs: { small: "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: _vm.$vuetify.breakpoint.xs } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "date-column mb-2", attrs: { shrink: "" } },
                    [
                      process.publicationDate
                        ? _c(
                            "span",
                            { staticClass: "d-block text-truncate body-1" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$d(new Date(process.publicationDate))
                                )
                              ),
                            ]
                          )
                        : process.stateDate
                        ? _c(
                            "span",
                            { staticClass: "d-block text-truncate body-1" },
                            [
                              _vm._v(
                                _vm._s(_vm.$d(new Date(process.stateDate)))
                              ),
                            ]
                          )
                        : _c(
                            "span",
                            {
                              staticClass:
                                "d-block text-truncate body-1 text--disabled",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("brand.details.noDateAvailable"))
                              ),
                            ]
                          ),
                    ]
                  ),
                  _c(
                    "v-flex",
                    [
                      process.processType ||
                      process.processState ||
                      process.publicationSection ||
                      process.publicationIdentifier
                        ? [
                            process.processType
                              ? _c("BrandDetailsTextField", {
                                  staticClass: "mb-2",
                                  attrs: {
                                    title: _vm.$t("brand.details.processType"),
                                    value: process.processType.description,
                                    alternative1:
                                      process.processType.shortDescription,
                                    alternative2: process.processType.key,
                                    wrap: true,
                                  },
                                })
                              : _vm._e(),
                            process.processState
                              ? _c("BrandDetailsTextField", {
                                  staticClass: "mb-2",
                                  attrs: {
                                    title: _vm.$t("brand.details.processState"),
                                    value: process.processState.description,
                                    alternative1:
                                      process.processState.shortDescription,
                                    alternative2: process.processState.key,
                                    wrap: true,
                                  },
                                })
                              : _vm._e(),
                            process.publicationSection ||
                            process.publicationIdentifier
                              ? _c("BrandDetailsTextField", {
                                  staticClass: "mb-2",
                                  attrs: {
                                    title: _vm.$t("brand.details.publication"),
                                    value: _vm.$util.concat(
                                      " ",
                                      process.publicationSection,
                                      process.publicationIdentifier
                                    ),
                                    wrap: true,
                                  },
                                })
                              : _vm._e(),
                          ]
                        : [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-block text-truncate body-1 text--disabled",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("brand.details.noStateAvailable")
                                  )
                                ),
                              ]
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      )
    : _c("div", [
        _c(
          "span",
          { staticClass: "d-block text-truncate body-1 text--disabled" },
          [_vm._v(_vm._s(_vm.$t("brand.details.notAvailable")))]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }