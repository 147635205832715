/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandIndexItem } from '../models/BrandIndexItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandIndexService {
    /**
     * Gets the brand index item data.
     * @param id The brand index item id.
     * @returns BrandIndexItem Success
     * @throws ApiError
     */
    public static getIndexItem(
        id: string,
    ): CancelablePromise<BrandIndexItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/BrandIndex/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
}
