var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "", "max-width": "800" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.$t("subscriptionPopup.title"))),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.activeSubscription.object
                    ? _c(
                        "v-tabs",
                        { attrs: { vertical: "" } },
                        [
                          _c(
                            "v-tab",
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("fa-light fa-circle-info"),
                              ]),
                              _vm._v(" Info "),
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("fa-brands fa-paypal"),
                              ]),
                              _vm._v(" Billing "),
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("fa-light fa-bolt"),
                              ]),
                              _vm._v(" Actions "),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _vm.activeSubscription.object.product
                                            ? _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    light: "",
                                                    elevation: "0",
                                                    color: "white",
                                                    rounded: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      attrs: { "two-line": "" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-avatar",
                                                        { attrs: { tile: "" } },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "tertiary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-light " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .activeSubscription
                                                                      .object
                                                                      .product
                                                                      .image
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .activeSubscription
                                                                      .object
                                                                      .product
                                                                      .name
                                                                  ) +
                                                                  " Subscription "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.activeSubscription
                                                            .object.product
                                                            .description
                                                            ? _c(
                                                                "v-list-item-subtitle",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .activeSubscription
                                                                          .object
                                                                          .product
                                                                          .description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c(
                                                            "SubscriptionState",
                                                            {
                                                              attrs: {
                                                                state:
                                                                  _vm
                                                                    .activeSubscription
                                                                    .object
                                                                    .state,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "SubscriptionDetailsTable",
                                                        {
                                                          attrs: {
                                                            subscription:
                                                              _vm
                                                                .activeSubscription
                                                                .object,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _vm.activeSubscription.object
                                        .payPalSubscription &&
                                      _vm.activeSubscription.object
                                        .payPalSubscription.billingInfo
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    light: "",
                                                    elevation: "0",
                                                    color: "white",
                                                    rounded: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-avatar",
                                                        { attrs: { tile: "" } },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "tertiary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-brands fa-paypal"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " PayPal Payment Info "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-simple-table", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "tbody",
                                                                    [
                                                                      _vm
                                                                        .activeSubscription
                                                                        .object
                                                                        .payPalSubscription
                                                                        .billingInfo
                                                                        .nextBillingTime
                                                                        ? _c(
                                                                            "tr",
                                                                            [
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Next billing"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$d(
                                                                                        new Date(
                                                                                          _vm.activeSubscription.object.payPalSubscription.billingInfo.nextBillingTime
                                                                                        ),
                                                                                        "dateTime"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .activeSubscription
                                                                        .object
                                                                        .payPalSubscription
                                                                        .billingInfo
                                                                        .outstandingBalance
                                                                        ? _c(
                                                                            "tr",
                                                                            [
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Outstanding balance"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$n(
                                                                                        Number(
                                                                                          _vm
                                                                                            .activeSubscription
                                                                                            .object
                                                                                            .payPalSubscription
                                                                                            .billingInfo
                                                                                            .outstandingBalance
                                                                                            .value
                                                                                        )
                                                                                      )
                                                                                    ) +
                                                                                      " €"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .activeSubscription
                                                                        .object
                                                                        .payPalSubscription
                                                                        .billingInfo
                                                                        .failedPaymentsCount
                                                                        ? _c(
                                                                            "tr",
                                                                            [
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Number of failed payments"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .activeSubscription
                                                                                        .object
                                                                                        .payPalSubscription
                                                                                        .billingInfo
                                                                                        .failedPaymentsCount
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .activeSubscription
                                                                        .object
                                                                        .payPalSubscription
                                                                        .billingInfo
                                                                        .lastFailedPayment
                                                                        ? [
                                                                            _vm
                                                                              .activeSubscription
                                                                              .object
                                                                              .payPalSubscription
                                                                              .billingInfo
                                                                              .lastFailedPayment
                                                                              .time
                                                                              ? _c(
                                                                                  "tr",
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Last failed payment"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$d(
                                                                                              new Date(
                                                                                                _vm.activeSubscription.object.payPalSubscription.billingInfo.lastFailedPayment.time
                                                                                              ),
                                                                                              "dateTime"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm
                                                                              .activeSubscription
                                                                              .object
                                                                              .payPalSubscription
                                                                              .billingInfo
                                                                              .lastFailedPayment
                                                                              .amount
                                                                              ? _c(
                                                                                  "tr",
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Last failed payment amount"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$n(
                                                                                              Number(
                                                                                                _vm
                                                                                                  .activeSubscription
                                                                                                  .object
                                                                                                  .payPalSubscription
                                                                                                  .billingInfo
                                                                                                  .lastFailedPayment
                                                                                                  .amount
                                                                                                  .value
                                                                                              )
                                                                                            )
                                                                                          ) +
                                                                                            " €"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm
                                                                              .activeSubscription
                                                                              .object
                                                                              .payPalSubscription
                                                                              .billingInfo
                                                                              .lastFailedPayment
                                                                              .reasonCode
                                                                              ? _c(
                                                                                  "tr",
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Reason"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .activeSubscription
                                                                                              .object
                                                                                              .payPalSubscription
                                                                                              .billingInfo
                                                                                              .lastFailedPayment
                                                                                              .reasonCode
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm
                                                                              .activeSubscription
                                                                              .object
                                                                              .payPalSubscription
                                                                              .billingInfo
                                                                              .lastFailedPayment
                                                                              .nextPaymentRetryTime
                                                                              ? _c(
                                                                                  "tr",
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Next payment retry"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$d(
                                                                                              new Date(
                                                                                                _vm.activeSubscription.object.payPalSubscription.billingInfo.lastFailedPayment.nextPaymentRetryTime
                                                                                              ),
                                                                                              "dateTime"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .activeSubscription
                                                                        .object
                                                                        .payPalSubscription
                                                                        .billingInfo
                                                                        .lastPayment
                                                                        ? [
                                                                            _vm
                                                                              .activeSubscription
                                                                              .object
                                                                              .payPalSubscription
                                                                              .billingInfo
                                                                              .lastPayment
                                                                              .time
                                                                              ? _c(
                                                                                  "tr",
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Last payment"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$d(
                                                                                              new Date(
                                                                                                _vm.activeSubscription.object.payPalSubscription.billingInfo.lastPayment.time
                                                                                              ),
                                                                                              "dateTime"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm
                                                                              .activeSubscription
                                                                              .object
                                                                              .payPalSubscription
                                                                              .billingInfo
                                                                              .lastPayment
                                                                              .amount
                                                                              ? _c(
                                                                                  "tr",
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Payed amount"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$n(
                                                                                              Number(
                                                                                                _vm
                                                                                                  .activeSubscription
                                                                                                  .object
                                                                                                  .payPalSubscription
                                                                                                  .billingInfo
                                                                                                  .lastPayment
                                                                                                  .amount
                                                                                                  .value
                                                                                              )
                                                                                            )
                                                                                          ) +
                                                                                            " €"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        : _vm._e(),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2949332692
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                light: "",
                                                elevation: "0",
                                                color: "white",
                                                rounded: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-avatar",
                                                    { attrs: { tile: "" } },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "tertiary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-light fa-bolt"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(" Actions "),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-list",
                                                    {
                                                      attrs: {
                                                        "two-line": "",
                                                        color: "transparent",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    "Upgrade"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle",
                                                                [
                                                                  _vm._v(
                                                                    "Do you want to change your existing subscription?"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-action",
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.upgrade()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Upgrade"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm
                                                                .activeSubscription
                                                                .object
                                                                .state !==
                                                              "Active",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    "Unsubscribe"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle",
                                                                [
                                                                  _vm._v(
                                                                    "You don't need our service anymore?"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-action",
                                                            [
                                                              _c(
                                                                "ConfirmationPopup",
                                                                {
                                                                  attrs: {
                                                                    yes: "",
                                                                    cancel: "",
                                                                    question:
                                                                      "",
                                                                    title:
                                                                      "Unsubscribe",
                                                                    text: "Do you really want to cancel your subscription?",
                                                                  },
                                                                  on: {
                                                                    yes: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.unsubscribe()
                                                                    },
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          plain:
                                                                                            "",
                                                                                          loading:
                                                                                            _vm.unsubscribing,
                                                                                        },
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Unsubscribe"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2975214253
                                                                    ),
                                                                  model: {
                                                                    value:
                                                                      _vm.showUnsubscribeConfirmation,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.showUnsubscribeConfirmation =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "showUnsubscribeConfirmation",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("subscriptionPopup.close")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }