import Vue from 'vue'
import Vuex from 'vuex'
import { AccountInfo } from '@azure/msal-browser';
import { User, Permission, Account, MasterDataItem, UsersService, PermissionsService, AccountsService, BrandTypesService, BrandStatesService, RegistrationOfficeCodesService, NiceClassesService, BrandBookmark, BrandBookmarksService, BrandBookmarkContainer, BrandBookmarkContainersService, BrandStateCategoryService, Subscription, SubscriptionsService, Product, Package, ProductsService, PackagesService } from '@/api/braendz'
import { BusyObject, BusyList } from '@/models/Busy';
import { PermissionResourceType } from '@/models/PermissionResourceType';
import { PermissionLevel } from '@/models/PermissionLevel';
import { keys } from 'object-hash';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userAccount: null as AccountInfo | null,
    user: new BusyObject<User>(),
    permissions: new BusyList<Permission>(),
    account: new BusyObject<Account>(),
    activeSubscription: new BusyObject<Subscription>(),
    activeSubscriptionFeatures: [] as string[],
    
    // Masterdata:
    registrationOfficeCodes: new BusyList<MasterDataItem>(),
    brandTypes: new BusyList<MasterDataItem>(),
    brandStates: new BusyList<MasterDataItem>(),
    brandStateCategories: new BusyList<MasterDataItem>(),
    niceClasses: new BusyList<MasterDataItem>(),

    // Products & Packages
    products: new BusyList<Product>(),
    packages: new BusyList<Package>(),
    
  },
  mutations: {
    setUserAccount: (state, userAccount: AccountInfo | null) => {
      state.userAccount = userAccount;
    },
    setActiveSubscription: (state, subscription: Subscription | null) => {
      state.activeSubscription.object = subscription;
    },
    setActiveSubscriptionFeatures: (state, features: string[]) => {
      state.activeSubscriptionFeatures = features;
    }
  },
  getters: {
    isUserAuthenticated: state => (): boolean => {
      return !!state.userAccount;
    },
    hasPermission: state => (resource: PermissionResourceType, level: PermissionLevel): boolean => {
      if(!state.permissions.list) {
        return false;
      }

      // Check if the user has the required level globally:
      const golbalPermission = state.permissions.list.find(p => p.resource === PermissionResourceType.Global);
      if(golbalPermission && golbalPermission.level && (level === (golbalPermission.level & level))) {
        return true;
      }

      // Check if the user has the required level for the requested resource:
      const resourcePermission = state.permissions.list.find(p => p.resource === resource);
      if(resourcePermission && resourcePermission.level && (level === (resourcePermission.level & level))) {
        return true;
      }

      return false;
    },
    hasFeature: state => (key: string): boolean => {
      return state.activeSubscriptionFeatures.includes(key);
      // if(!state.activeSubscription.object) {
      //   return false;
      // }

      // const features = state.activeSubscription.object.product?.features ?? [];
      // for(const subscriptionPackage of state.activeSubscription.object.packages ?? []) {
      //   if(subscriptionPackage.features) {
      //     features.push(...subscriptionPackage.features)
      //   }
      // }
      // return features.find(f => f.key === key && (f.limit === undefined || f.limit === null || f.usages ===  undefined || f.usages === null || f.usages < f.limit)) !== undefined;
    },

    // Master Data
    getRegistrationOfficeCode: state => (key: string): MasterDataItem | undefined => {
      return state.registrationOfficeCodes.list?.find(i => i.key?.toLocaleLowerCase() === key?.toLocaleLowerCase());
    },
    getBrandType: state => (key: string): MasterDataItem | undefined => {
      return state.brandTypes.list?.find(i => i.key?.toLocaleLowerCase() === key?.toLocaleLowerCase());
    },
    getBrandState: state => (key: string): MasterDataItem | undefined => {
      return state.brandStates.list?.find(i => i.key?.toLocaleLowerCase() === key?.toLocaleLowerCase());
    },
    getBrandStateCategory: state => (key: string): MasterDataItem | undefined => {
      return state.brandStateCategories.list?.find(i => i.key?.toLocaleLowerCase() === key?.toLocaleLowerCase());
    },
    getNiceClass: state => (number: string | number): MasterDataItem | undefined => {
      if(typeof number === 'string') {
        return state.niceClasses.list?.find(i => i.key === number);
      }
      else {
        return state.niceClasses.list?.find(i => i.key === number.toString());
      }
    },
    getProduct: state => (key: string): Product | undefined => {
      return state.products.list?.find(i => i.key.toLocaleLowerCase() === key.toLocaleLowerCase());
    },
    getPackage: state => (key: string): Package | undefined => {
      return state.packages.list?.find(i => i.key.toLocaleLowerCase() === key.toLocaleLowerCase());
    }
  },
  actions: {
    async refreshUser( { commit, state }): Promise<void> {
      await state.user.load(async () => {
        return await UsersService.getCurrentUser();
      });
    },
    async refreshPermissions( { commit, state }): Promise<void> {
      await state.permissions.load(async () => {
        return await PermissionsService.getCurrentUserPermissions();
      });
    },
    async refreshDefaultAccount( { commit, state }): Promise<void> {
      if(state.userAccount == null) {
        state.account.object = null;
      } else {
        await state.account.load(async () => {
          return await AccountsService.getCurrentUserDefaultAccount();
        });
      }
    },
    async refreshActiveSubscription( { commit, state }): Promise<void> {
      await state.activeSubscription.load(async () => {
        const result = await SubscriptionsService.getActiveSubscriptionCurrentUserDefaultAccount();
        
        // For performance resons, lets preload all features of the active subscription:
        const features = result.product?.features ?? [];
        for(const subscriptionPackage of result.packages ?? []) {
          if(subscriptionPackage.features) {
            features.push(...subscriptionPackage.features)
          }
        }
        const featureKeys = features.filter(f => f.limit === undefined || f.limit === null || f.usages ===  undefined || f.usages === null || f.usages < f.limit).map(f => f.key);
        state.activeSubscriptionFeatures = featureKeys;

        return result;
      });
    },
    async updateUserContext({ commit, dispatch }, userAccount): Promise<void> {
      commit('setUserAccount', userAccount);
      dispatch('refreshUser');
      dispatch('refreshActiveSubscription');
      dispatch('refreshPermissions');
      dispatch('refreshDefaultAccount');
    },
    async addFeatureUsage({ state }, key: string): Promise<void> {
      if(state.userAccount == null) return;

      await state.activeSubscription.load(async () => {
        await AccountsService.addFeatureUsageCurrentUserDefaultAccount(key);
        const result = await SubscriptionsService.getActiveSubscriptionCurrentUserDefaultAccount();

        // For performance resons, lets preload all features of the active subscription:
        const features = result.product?.features ?? [];
        for(const subscriptionPackage of result.packages ?? []) {
          if(subscriptionPackage.features) {
            features.push(...subscriptionPackage.features)
          }
        }
        const featureKeys = features.filter(f => f.limit === undefined || f.limit === null || f.usages ===  undefined || f.usages === null || f.usages < f.limit).map(f => f.key);
        state.activeSubscriptionFeatures = featureKeys;

        return result;
      });
    },
    // Master data:
    async refreshRegistrationOfficeCodes( { commit, state }): Promise<void> {
      await state.registrationOfficeCodes.load(async () => {
        return await RegistrationOfficeCodesService.getRegistrationOfficeCodes();
      });
    },
    async refreshBrandTypes( { commit, state }): Promise<void> {
      await state.brandTypes.load(async () => {
        return await BrandTypesService.getBrandTypes();
      });
    },
    async refreshBrandStates( { commit, state }): Promise<void> {
      await state.brandStates.load(async () => {
        return await BrandStatesService.getBrandStates();
      });
    },
    async refreshBrandStateCategories( { commit, state }): Promise<void> {
      await state.brandStateCategories.load(async () => {
        return await BrandStateCategoryService.getBrandStateCategories();
      });
    },
    async refreshNiceClasses( { commit, state }): Promise<void> {
      await state.niceClasses.load(async () => {
        return await NiceClassesService.getNiceClasses();
      });
    },
    async updateMasterData({ dispatch }): Promise<void> {
      dispatch('refreshRegistrationOfficeCodes');
      dispatch('refreshBrandTypes');
      dispatch('refreshBrandStates');
      dispatch('refreshBrandStateCategories');
      dispatch('refreshNiceClasses');
    },

    // Products & Packages
    async refreshProducts( { commit, state }): Promise<void> {
      await state.products.load(async () => {
        return await ProductsService.getProducts(false);
      });
    },
    async refreshPackages( { commit, state }): Promise<void> {
      await state.packages.load(async () => {
        return await PackagesService.getPackages(false);
      });
    },
    async updateProductsAndPackages({ dispatch }): Promise<void> {
      dispatch('refreshProducts');
      dispatch('refreshPackages');
    }
  },
  modules: {
  }
})
