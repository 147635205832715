/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandBookmark } from '../models/BrandBookmark';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandBookmarksService {
    /**
     * Gets the brand bookmarks for the specified brand bookmark container.
     * @param containerId The brand bookmark container ID.
     * @returns BrandBookmark Success
     * @throws ApiError
     */
    public static getBrandBookmarksOfContainer(
        containerId: number,
    ): CancelablePromise<Array<BrandBookmark>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/brands/bookmarkcontainers/{containerId}/bookmarks',
            path: {
                'containerId': containerId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Adds a brand bookmark to the specified container.
     * @param containerId The brand bookmark container ID.
     * @param requestBody The brand bookmark.
     * @returns BrandBookmark Success
     * @throws ApiError
     */
    public static addBrandBookmark(
        containerId: number,
        requestBody?: BrandBookmark,
    ): CancelablePromise<BrandBookmark> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/brands/bookmarkcontainers/{containerId}/bookmarks',
            path: {
                'containerId': containerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Gets the brand bookmark.
     * @param containerId The brand bookmark container ID.
     * @param id
     * @param brandId The brand id.
     * @returns BrandBookmark Success
     * @throws ApiError
     */
    public static getBrandBookmark(
        containerId: number,
        id: string,
        brandId?: string,
    ): CancelablePromise<BrandBookmark> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/brands/bookmarkcontainers/{containerId}/bookmarks/{id}',
            path: {
                'containerId': containerId,
                'id': id,
            },
            query: {
                'brandId': brandId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Deletes the brand bookmark.
     * @param containerId The container id.
     * @param id The brand id.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteBrandBookmark(
        containerId: number,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/brands/bookmarkcontainers/{containerId}/bookmarks/{id}',
            path: {
                'containerId': containerId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
