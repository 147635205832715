import { GlobalEventType } from "./GlobalEventType";
import { emitter } from "@/plugins/emitter";

export enum BusyReason {
    Loading = "LOADING",
    Updating = "UPDATING",
    Creating = "CREATING",
    Deleting = "DELETING"
}

export class BusyObject<T> {
    public isBusy = false;
    public reason: BusyReason | null = null;
    public error: any | null = null;
    public object: T | null = null;

    constructor(object?: T) {
        this.object = object ?? null;
    }

    public async load<E>(loadingFunction: () => Promise<T | null>, handleError: (error: any) => E = this.handleError, keepObjectWhileExecution = false, throwException = true, emitError = true): Promise<T | null> {
        return this.executeFunction(BusyReason.Loading, loadingFunction, handleError, keepObjectWhileExecution, throwException, emitError);
    }

    public async update<E>(updatingFunction: () => Promise<T | null>, handleError: (error: any) => E = this.handleError, keepObjectWhileExecution = true, throwException = true, emitError = true): Promise<T | null> {
        return this.executeFunction(BusyReason.Updating, updatingFunction, handleError, keepObjectWhileExecution, throwException, emitError);
    }

    public async create<E>(creatingFunction: () => Promise<T | null>, handleError: (error: any) => E = this.handleError, keepObjectWhileExecution = true, throwException = true, emitError = true): Promise<T | null> {
        return this.executeFunction(BusyReason.Creating, creatingFunction, handleError, keepObjectWhileExecution, throwException, emitError);
    }

    public async delete<E>(deletingFunction: () => Promise<T | null>, handleError: (error: any) => E = this.handleError, keepObjectWhileExecution = true, throwException = true, emitError = true): Promise<T | null> {
        return this.executeFunction(BusyReason.Deleting, deletingFunction, handleError, keepObjectWhileExecution, throwException, emitError);
    }

    public async executeAction<E>(reason: BusyReason, busyAction: () => Promise<void>, handleError: (error: any) => E = this.handleError, throwException = true, emitError = true): Promise<void> {
        this.startOperation(reason);

        try {
            const result = await busyAction();
            this.finishOperation(null);
            return result;
        }
        catch(error) {
            const handledError = handleError(error)
            this.finishOperation(handledError);

            if(emitError) {
                emitter.emit(GlobalEventType.ErrorOccurred, handledError);
            }

            if(throwException) {
                throw(handledError);
            }
        }
    }

    public async executeFunction<E>(reason: BusyReason, busyFunction: () => Promise<T | null>, handleError: (error: any) => E = this.handleError, keepObjectWhileExecution = false, throwException = true, emitError = true): Promise<T | null> {
        this.startOperation(reason);

        if(!keepObjectWhileExecution)
            this.object = null;

        try {
            const result = await busyFunction();
            this.finishOperation(null, result);
            return result;
        }
        catch(error) {
            const handledError = handleError(error)
            this.finishOperation(handledError);

            if(emitError) {
                emitter.emit(GlobalEventType.ErrorOccurred, handledError);
            }

            if(throwException) {
                throw(handledError);
            }

            return null;
        }
    }

    public handleError(error: any): any {
        return error;
    }

    public startOperation(reason: BusyReason): void {
        this.isBusy = true;
        this.error = null;
        this.reason = reason;
    }

    public finishOperation(error: any | null, object?: T | null): void {
        this.isBusy = false;
        this.reason = null;
        this.error = error;

        if (object !== undefined) {
            this.object = object;
        }
    }

    public reset() {
        this.isBusy = false;
        this.reason = null;
        this.error = null;
        this.object = null;
    }
}

export class BusyList<T> {
    public isBusy = false;
    public reason: BusyReason | null = null;
    public error: any | null = null;
    public list: T[] = [];

    public get hasItems(): boolean {
        return this.list.length > 0;
    }

    //public async load<E>(loadingFunction: () => Promise<T[] | null>, handleError: (error: any) => E = this.handleError, throwException = true, emitError = true): Promise<T[] | null> {
    public async load<E>(loadingFunction: () => Promise<T[] | null>, handleError: (error: any) => E = this.handleError, throwException = true, emitError = true): Promise<T[] | null> {
        return this.executeFunction(BusyReason.Loading, loadingFunction, handleError, throwException, emitError);
    }

    public async delete<E>(deletingFunction: () => Promise<T | null>, handleError: (error: any) => E = this.handleError, throwException = true, emitError = true): Promise<T | null> {
        this.startOperation(BusyReason.Deleting);

        try {
            const deletedItem = await deletingFunction();
            const list = this.list.filter(i => i !== deletedItem);
            this.finishOperation(null, list);
            return deletedItem;
        }
        catch(error) {
            const handledError = handleError(error)
            this.finishOperation(handledError);

            if(emitError) {
                emitter.emit(GlobalEventType.ErrorOccurred, handledError);
            }

            if(throwException) {
                throw(handledError);
            }

            return null;
        }
    }

    public async executeAction<E>(reason: BusyReason, busyAction: () => Promise<void>, handleError: (error: any) => E = this.handleError, throwException = true, emitError = true): Promise<void> {
        this.startOperation(reason);

        try {
            const result = await busyAction();
            this.finishOperation(null);
            return result;
        }
        catch(error) {
            const handledError = handleError(error)
            this.finishOperation(handledError);

            if(emitError) {
                emitter.emit(GlobalEventType.ErrorOccurred, handledError);
            }

            if(throwException) {
                throw(handledError);
            }
        }
    }

    public async executeFunction<E>(reason: BusyReason, busyFunction: () => Promise<T[] | null>, handleError: (error: any) => E = this.handleError, throwException = true, emitError = true): Promise<T[] | null> {
        this.startOperation(reason);
        this.list = [];

        try {
            const result = await busyFunction();
            this.finishOperation(null, result);
            return result;
        }
        catch(error) {
            const handledError = handleError(error)
            this.finishOperation(handledError);

            if(emitError) {
                emitter.emit(GlobalEventType.ErrorOccurred, handledError);
            }

            if(throwException) {
                throw(handledError);
            }

            return null;
        }
    }

    public handleError(error: any): any {
        return error;
    }


    public startOperation(reason: BusyReason): void {
        this.isBusy = true;
        this.error = null;
        this.reason = reason;
    }

    public finishOperation(error: any | null, list?: T[] | null): void {
        this.isBusy = false;
        this.reason = null;
        this.error = error;

        if (list !== undefined) {
            this.list = list ?? [];
        }
    }

    public reset() {
        this.isBusy = false;
        this.reason = null;
        this.error = null;
        this.list = [];
    }
}
