var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statistic.object && _vm.statistic.object.brandThumbnails
    ? _c("div", [
        _c(
          "div",
          { staticClass: "d-block" },
          [
            _c(
              "VueSlickCarousel",
              {
                staticClass: "align-height",
                attrs: {
                  arrows: false,
                  accessibility: "",
                  autoplay: "",
                  autoplaySpeed: 2000,
                  swipeToSlide: "",
                  focusOnSelect: "",
                  centerMode: "",
                  slidesToShow: _vm.itemsToShow,
                  slidesToScroll: 1,
                },
              },
              _vm._l(
                _vm.statistic.object.brandThumbnails,
                function (thumbnail, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "pa-5" },
                    [
                      _c("BrandThumbnailCard", {
                        attrs: { thumbnail: thumbnail },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
      ])
    : _vm.statistic.isBusy
    ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }