var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", [
    _c(
      "section",
      { staticClass: "theme--light pa-16", attrs: { id: "header" } },
      [
        _c(
          "v-container",
          { attrs: { "fill-height": "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c("v-img", {
                      attrs: {
                        src: require("@/assets/images/404.svg"),
                        contain: "",
                        "max-height": "400",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "text-center" },
                  [
                    _c("h1", { staticClass: "header" }, [
                      _vm._v(_vm._s(_vm.$t("notFound.header"))),
                    ]),
                    _c("div", {
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("notFound.description")),
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { depressed: "", dark: "", color: "tertiary" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("notFound.goBack")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }