var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      attrs: { persistent: "" },
      scopedSlots: _vm._u(
        [
          _vm.showIcon
            ? {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-fab-transition",
                      [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  fixed: "",
                                  small: "",
                                  left: "",
                                  bottom: "",
                                  fab: "",
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("fa-light fa-cookie-bite")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pa-5",
          staticStyle: { opacity: "0.95" },
          attrs: { flat: "", tile: "", dark: "", color: "background" },
        },
        [
          _c("v-card-title", { staticClass: "justify-center pt-0" }, [
            _vm._v(" " + _vm._s(_vm.$t("cookie.banner.title")) + " "),
          ]),
          _c("v-card-text", { staticClass: "text-center pt-0" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.$t("cookie.banner.content")) },
            }),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "justify-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-4 black--text text--lighten-3",
                  attrs: { color: "white darken-3", light: "", width: "200" },
                  on: { click: _vm.acceptAll },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" fa-light fa-check "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("cookie.banner.accept")) + " "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-4 white--text text--darken-3",
                  attrs: {
                    outlined: "",
                    color: "white darken-3",
                    width: "200",
                  },
                  on: { click: _vm.openSettings },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" fa-light fa-gear "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("cookie.banner.settings")) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CookieDialog", {
        model: {
          value: _vm.settingOpened,
          callback: function ($$v) {
            _vm.settingOpened = $$v
          },
          expression: "settingOpened",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }