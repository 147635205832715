var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "fill-height",
      attrs: { light: "", width: 300, elevation: "3", rounded: "" },
    },
    [
      _c(
        "v-card-title",
        { staticStyle: { display: "block" } },
        [
          _c(
            "v-list-item",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-avatar",
                { attrs: { tile: "" } },
                [
                  _c("v-img", {
                    attrs: {
                      src: _vm.$braendz.registrationOfficeCodeFlag(
                        _vm.thumbnail.registrationOfficeCode
                      ),
                      contain: "",
                      "max-height": "50",
                      "max-width": "50",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  !_vm.thumbnail.brandType ||
                  _vm.thumbnail.brandType !== "Figurative"
                    ? _c(
                        "TruncateTooltip",
                        { attrs: { label: _vm.thumbnail.name, top: "" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h5 text-truncate" },
                            [_vm._v(" " + _vm._s(_vm.thumbnail.name) + " ")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-list-item-title",
                        [_c("v-icon", [_vm._v("$no-text")])],
                        1
                      ),
                  _vm.brandType && _vm.brandType.description
                    ? _c("v-list-item-subtitle", [
                        _vm._v(" " + _vm._s(_vm.brandType.description) + " "),
                      ])
                    : _vm.thumbnail.brandType
                    ? _c("v-list-item-subtitle", [
                        _vm._v(" " + _vm._s(_vm.thumbnail.brandType) + " "),
                      ])
                    : _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "statistics.brandThumbnailCard.valueNotAvailable"
                              )
                            ) +
                            " "
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-3" }),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticStyle: { height: "150px" }, attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-center", attrs: { cols: "12" } },
                [
                  _vm.fullBrandLogoUrl
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.fullBrandLogoUrl,
                          contain: "",
                          "max-height": "100",
                        },
                      })
                    : _c("v-icon", { attrs: { size: "100" } }, [
                        _vm._v("fa-light fa-image-slash"),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-divider", { staticClass: "mt-2 mx-3" }),
      _c(
        "v-card-actions",
        [
          _c("BrandDetailsPopup", {
            attrs: { id: _vm.thumbnail.brandId },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b({ attrs: { text: "" } }, "v-btn", attrs, false),
                        on
                      ),
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("fa-light fa-memo-circle-info"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "statistics.brandThumbnailCard.detailsButtonTitle"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }