/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterDataItem } from '../models/MasterDataItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessStatesService {
    /**
     * Gets all known process states and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getProcessStates(): CancelablePromise<Array<MasterDataItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ProcessStates',
        });
    }
    /**
     * Adds or updates a process state in the data storage.
     * @param requestBody The new or existing process state.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static upsertProcessState(
        requestBody?: MasterDataItem,
    ): CancelablePromise<MasterDataItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/ProcessStates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Gets the process state by its key and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The key.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getProcessStateByKey(
        key: string,
    ): CancelablePromise<MasterDataItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ProcessStates/{key}',
            path: {
                'key': key,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Deletes the process state by its key.
     * @param key The key.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProcessState(
        key: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/ProcessStates/{key}',
            path: {
                'key': key,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
