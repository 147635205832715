var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-badge",
    _vm._b(
      {
        class: { small: _vm.small },
        attrs: {
          value: !_vm.hasFeature,
          color: "tertiary",
          icon: _vm.requiredLogin
            ? "fa-solid fa-right-to-bracket"
            : "fa-solid fa-lock",
        },
      },
      "v-badge",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }