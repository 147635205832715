import { render, staticRenderFns } from "./Chart.vue?vue&type=template&id=77c8f1fc&scoped=true&"
import script from "./Chart.vue?vue&type=script&lang=ts&"
export * from "./Chart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c8f1fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/source/client-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77c8f1fc')) {
      api.createRecord('77c8f1fc', component.options)
    } else {
      api.reload('77c8f1fc', component.options)
    }
    module.hot.accept("./Chart.vue?vue&type=template&id=77c8f1fc&scoped=true&", function () {
      api.rerender('77c8f1fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Statistics/Chart.vue"
export default component.exports