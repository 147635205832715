var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column text-center" },
    [
      _c("v-icon", { attrs: { large: "", color: _vm.iconColor } }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
      _c("div", { staticClass: "display-1 font-weight-black mt-2" }, [
        _vm.count
          ? _c("span", { style: { color: _vm.counterColor } }, [
              _vm._v(_vm._s(_vm.$n(_vm.count))),
            ])
          : _c("span", { style: { color: _vm.counterColor } }, [_vm._v("N/A")]),
      ]),
      _c("div", { staticClass: "title font-weight-regular text-uppercase" }, [
        _c("span", { style: { color: _vm.descriptionColor } }, [
          _vm._v(_vm._s(_vm.label)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }