var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "", "max-width": "800" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function (dialog) {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      _vm.brand.isBusy
                        ? _c("v-toolbar-title", [
                            _vm._v(_vm._s(_vm.$t("popup.titleLoading"))),
                          ])
                        : _vm.brand.object
                        ? _c("v-toolbar-title", [
                            _vm._v(_vm._s(_vm.brand.object.name)),
                          ])
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  dark: "",
                                                  to: {
                                                    name: "Brand",
                                                    params: { id: _vm.id },
                                                  },
                                                  target: "_blank",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "fa-light fa-arrow-up-right-from-square"
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Open in new window")])]
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: { rounded: "0", "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var menu = ref.on
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var tooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              dark: "",
                                                            },
                                                          },
                                                          Object.assign(
                                                            {},
                                                            menu,
                                                            tooltip
                                                          )
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "fa-light fa-share-nodes"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Share this brand"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "email",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "linkedin",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "xing",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "twitter",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "whatsapp",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "facebook",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "messenger",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "skype",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "sms",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                  _c("SocialMediaButton", {
                                    attrs: {
                                      type: "list-item",
                                      network: "telegram",
                                      url: _vm.$util.absoluteUrl(
                                        _vm.$router.resolve({
                                          name: "Brand",
                                          params: { id: _vm.id },
                                        }).href
                                      ),
                                      title: _vm.socialMediaTitle,
                                      description: _vm.$t(
                                        "brandDetailsPopup.socialMedia.description"
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              dialog.value = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("BrandDetails", {
                        staticClass: "py-6",
                        attrs: { brand: _vm.brand },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }