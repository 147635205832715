var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Chart", {
    attrs: {
      width: _vm.width,
      height: _vm.height,
      size: _vm.size,
      series: _vm.series,
      legendPosition: _vm.legendPosition,
      type: "donut",
      responsiveBreakpoint: _vm.responsiveBreakpoint,
      totalEnabled: _vm.totalEnabled,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }