var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("tbody", [
              _vm.subscription.state
                ? _c("tr", [
                    _c("td", [_vm._v("Status")]),
                    _c(
                      "td",
                      [
                        _c("SubscriptionState", {
                          attrs: {
                            state: _vm.subscription.state,
                            design: "Text",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.subscription.product && _vm.subscription.product.name
                ? _c("tr", [
                    _c("td", [_vm._v("Type")]),
                    _c("td", [_vm._v(_vm._s(_vm.subscription.product.name))]),
                  ])
                : _vm._e(),
              _vm.subscription.payPalSubscription &&
              _vm.subscription.payPalSubscription.id
                ? _c("tr", [
                    _c("td", [_vm._v("Subscription ID")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.subscription.payPalSubscription.id)),
                    ]),
                  ])
                : _vm._e(),
              _vm.subscription.product && _vm.subscription.product.credits
                ? _c("tr", [
                    _c("td", [_vm._v("Remaining Credits")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.subscription.product.credits)),
                    ]),
                  ])
                : _vm._e(),
              _vm.subscription.product && _vm.subscription.product.billingPlan
                ? _c("tr", [
                    _c("td", [_vm._v("Billing Plan")]),
                    _vm.subscription.product.billingPlan === "Monthly"
                      ? _c("td", [_vm._v("Monthly")])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.subscription.createdUtc ||
              _vm.subscription.validFrom ||
              (_vm.subscription.payPalSubscription &&
                _vm.subscription.payPalSubscription.startTime)
                ? _c("tr", [
                    _c("td", [_vm._v("Start Date")]),
                    _vm.subscription.payPalSubscription &&
                    _vm.subscription.payPalSubscription.startTime
                      ? _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$d(
                                new Date(
                                  _vm.subscription.payPalSubscription.startTime
                                ),
                                "dateTime"
                              )
                            )
                          ),
                        ])
                      : _vm.subscription.validFrom
                      ? _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$d(
                                new Date(_vm.subscription.validFrom),
                                "dateTime"
                              )
                            )
                          ),
                        ])
                      : _vm.subscription.createdUtc
                      ? _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$d(
                                new Date(_vm.subscription.createdUtc),
                                "dateTime"
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.subscription.validUntil
                ? _c("tr", [
                    _c("td", [_vm._v("End Date")]),
                    _vm.subscription.validUntil
                      ? _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$d(
                                new Date(_vm.subscription.validUntil),
                                "dateTime"
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.subscription.payPalSubscription &&
              _vm.subscription.payPalSubscription.subscriber &&
              (_vm.subscription.payPalSubscription.subscriber.givenName ||
                _vm.subscription.payPalSubscription.subscriber.surname)
                ? _c("tr", [
                    _c("td", [_vm._v("Subscriber")]),
                    _vm.subscription.payPalSubscription.subscriber.givenName &&
                    _vm.subscription.payPalSubscription.subscriber.surname
                      ? _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.subscription.payPalSubscription.subscriber
                                .givenName
                            ) +
                              " " +
                              _vm._s(
                                _vm.subscription.payPalSubscription.subscriber
                                  .surname
                              )
                          ),
                        ])
                      : _vm.subscription.payPalSubscription.subscriber.surname
                      ? _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.subscription.payPalSubscription.subscriber
                                .surname
                            )
                          ),
                        ])
                      : _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.subscription.payPalSubscription.subscriber
                                .givenName
                            )
                          ),
                        ]),
                  ])
                : _vm._e(),
              _vm.subscription.payPalSubscription &&
              _vm.subscription.payPalSubscription.subscriber &&
              _vm.subscription.payPalSubscription.subscriber.emailAddress
                ? _c("tr", [
                    _c("td", [_vm._v("Subscriber eMail")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.subscription.payPalSubscription.subscriber
                            .emailAddress
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.subscription.payPalSubscription &&
              _vm.subscription.payPalSubscription.subscriber &&
              _vm.subscription.payPalSubscription.subscriber.phone
                ? _c("tr", [
                    _c("td", [_vm._v("Subscriber's Phone")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.subscription.payPalSubscription.subscriber.phone
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }