var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", [
    _c(
      "section",
      { staticClass: "theme--dark", attrs: { id: "header" } },
      [
        _c(
          "v-container",
          { attrs: { "fill-height": "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "text-center", attrs: { cols: "12" } },
                  [
                    _c("v-img", {
                      staticClass: "d-block",
                      attrs: {
                        dark: "",
                        src: require("@/assets/logos/braendz-blue-frame.svg"),
                        contain: "",
                        height: "40vh",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c("v-col", { attrs: { cols: "10", md: "6" } }, [
                  _c("div", { staticClass: "caption" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("home.header.createTitle"))),
                    ]),
                    _c("h3", { staticClass: "sub-title" }, [
                      _vm._v(_vm._s(_vm.$t("home.header.createDescription"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "button-group" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              outlined: "",
                              dark: "",
                              color: "primary",
                              to: { name: "Home", hash: "#create" },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("home.header.learnMore")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              depressed: "",
                              dark: "",
                              color: "primary",
                              to: { name: "Create" },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("home.header.start")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("v-col", { attrs: { cols: "10", md: "6" } }, [
                  _c("div", { staticClass: "caption" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("home.header.discoverTitle"))),
                    ]),
                    _c("h3", { staticClass: "sub-title" }, [
                      _vm._v(_vm._s(_vm.$t("home.header.discoverDescription"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "button-group" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              outlined: "",
                              dark: "",
                              color: "primary",
                              to: { name: "Home", hash: "#discover" },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("home.header.learnMore")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              depressed: "",
                              dark: "",
                              color: "primary",
                              to: { name: "Search" },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("home.header.start")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("ScrollDownButton", {
                      staticClass: "mx-auto",
                      attrs: {
                        to: { name: "Home", hash: "#create" },
                        dark: true,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "create" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "headline text-center",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticClass: "title text-uppercase" }, [
                          _vm._v(_vm._s(_vm.$t("home.sectionCreate.headline"))),
                        ]),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-16",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: "center",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "30px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("home.sectionCreate.subtitle")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("home.sectionCreate.text")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        depressed: "",
                                        dark: "",
                                        color: "tertiary",
                                        to: { name: "Create" },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("home.header.start"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-5 text-center",
                                  attrs: {
                                    width: "300",
                                    color: "tertiary",
                                    elevation: "4",
                                    "data-aos": "fade-down",
                                  },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass: "justify-center white--text",
                                      staticStyle: {
                                        "font-size": "30px",
                                        "word-break": "break-word",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mb-4",
                                              attrs: {
                                                color: "white",
                                                "x-large": "",
                                              },
                                            },
                                            [_vm._v("fa-light fa-rocket")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "home.sectionCreate.tile1Title"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "justify-center white--text",
                                      staticStyle: {
                                        "font-size": "15px",
                                        "font-weight": "400",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "home.sectionCreate.tile1Text"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "discover" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "headline text-center",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(_vm.$t("home.sectionDiscover.headline"))
                          ),
                        ]),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-16",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: "center",
                        orderOnBreak: "right-first",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-5 text-center",
                                  attrs: {
                                    width: "300",
                                    color: "orange lighten-1",
                                    elevation: "4",
                                    "data-aos": "fade-down",
                                  },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass: "justify-center white--text",
                                      staticStyle: {
                                        "font-size": "30px",
                                        "word-break": "break-word",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mb-4",
                                              attrs: {
                                                color: "white",
                                                "x-large": "",
                                              },
                                            },
                                            [_vm._v("fa-light fa-mind-share")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "home.sectionDiscover.tile1Title"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "justify-center white--text",
                                      staticStyle: {
                                        "font-size": "15px",
                                        "font-weight": "400",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "home.sectionDiscover.tile1Text"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "30px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "home.sectionDiscover.subtitle"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("home.sectionDiscover.text")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "button-group" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outlined: "",
                                            dark: "",
                                            color: "orange lighten-1",
                                            to: {
                                              name: "Home",
                                              hash: "trademarks",
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.sectionDiscover.goToTrademarks"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            depressed: "",
                                            dark: "",
                                            color: "orange lighten-1",
                                            to: { name: "Search" },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("home.header.start"))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "trademarks" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "headline text-center",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(_vm.$t("home.sectionTrademarks.headline"))
                          ),
                        ]),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-16",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "30px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "home.sectionTrademarks.total.title"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "home.sectionTrademarks.total.description"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.globalBrandStatistic.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "home.sectionTrademarks.total.counterLabel"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("TwoColumns", {
                      staticClass: "py-16",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "stretch"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                        orderOnBreak: "right-first",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("BrandsByOfficeBarChart", {
                                staticClass: "chart",
                                attrs: {
                                  width: _vm.$vuetify.breakpoint.smAndDown
                                    ? "100%"
                                    : 650,
                                  height: "300",
                                  data: _vm.globalBrandStatistic.object,
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "30px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "home.sectionTrademarks.offices.title"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "home.sectionTrademarks.offices.description"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("TwoColumns", {
                      staticClass: "py-16",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "30px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "home.sectionTrademarks.stateCategory.title"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "home.sectionTrademarks.stateCategory.description"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("BrandsByStateCategoryDonutChart", {
                                attrs: {
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  data: _vm.globalBrandStatistic.object,
                                  "data-aos": "fade-down",
                                  labelTotalEnabled: false,
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("TwoColumns", {
                      staticClass: "py-16",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                        orderOnBreak: "right-first",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("BrandsByBrandTypeDonutChart", {
                                attrs: {
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  data: _vm.globalBrandStatistic.object,
                                  "data-aos": "fade-down",
                                  labelTotalEnabled: false,
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "30px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "home.sectionTrademarks.type.title"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "home.sectionTrademarks.type.description"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "30px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.sectionTrademarks.latestBrands.title"
                              )
                            )
                          ),
                        ]),
                        _c("p", {
                          staticClass: "description",
                          staticStyle: { "font-size": "20px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "home.sectionTrademarks.latestBrands.description"
                              )
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("BrandThumbnailsCarousel", {
          staticClass: "pb-16",
          attrs: {
            data: _vm.globalBrandStatistic.object,
            "data-aos": "fade-down",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }