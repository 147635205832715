/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Brand } from '../models/Brand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandsService {
    /**
     * Gets the brand data.
     * @param id The brand id.
     * @returns Brand Success
     * @throws ApiError
     */
    public static getBrand(
        id: string,
    ): CancelablePromise<Brand> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Brands/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                422: `Client Error`,
            },
        });
    }
}
