import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import i18n from '@/i18n';
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import { PositionResult } from 'vue-router/types/router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/insights',
    name: 'Insights',
    component: () => import('../views/Insights.vue')
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('../views/Pricing.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    props: (route) => ({ query: route.query.q })
  },
  {
    path: '/brands/:id',
    name: 'Brand',
    component: () => import('../views/Brand.vue'),
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/Chat.vue')
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import('../views/Create.vue')
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: () => import('../views/Monitor.vue')
  },
  {
    path: '/mybraendz',
    name: 'MyBraendz',
    component: () => import('../views/MyBraendz.vue')
  },
  {
    path: '/storedqueries/:queryId/results/:resultId',
    name: 'StoredBrandQueryResult',
    component: () => import('../views/StoredBrandQueryResult.vue'),
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import('../views/Subscribe.vue'),
    props: (route) => ({ product: route.query.product })
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('../views/Imprint.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/Feedback.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue')
  },
  {
    path: '*',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    // Authentication sends the state information as #state= which needs to be ignored for scorlling.
    if(to.hash && !to.hash.startsWith('#state=')) {
      return { behavior: 'smooth', selector: to.hash} as PositionResult;
    }
    else if(savedPosition) {
      return { y: savedPosition.y } as PositionResult;
    }
    else {
      return { y: 0 } as PositionResult;
    }
  }
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {

  // Process common query Paramters:
  const queryParams = to.query;

  // Language:
  const langValue = queryParams.lang;
  if((langValue as string)) {
    i18n.locale = langValue as string;
  }

  next();
});

router.afterEach((to: Route, from: Route) => {

  // Add default query parameters:
  if(!to.query.lang || to.query.lang !== i18n.locale) {
    router.replace({ query: {... to.query, ...{ lang: i18n.locale }}, hash: to.hash });
  }
});

export default router
