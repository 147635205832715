




































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyObject } from '@/models/Busy';
import { Brand, BrandsService } from '@/api/braendz';

import BrandDetails from '@/components/Brands/BrandDetails.vue'
import SocialMediaButton from '@/components/Social/SocialMediaButton.vue'

@Component({
    components: {
        BrandDetails,
        SocialMediaButton
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class BrandDetailsPopup extends Vue {
    // Member
    public visibility = false;
    public brand = new BusyObject<Brand>();

    // Properties
    @Prop({ required: false })
    public model?: boolean;

    @Prop({ required: true })
    public id!: string;

    // Getter & Setter
    public get visible(): boolean {
        return this.model ?? this.visibility;
    }
    public set visible(value: boolean) {
        this.visibility = value;
        this.$emit('visibilityChanged', value);
    }

    public get socialMediaTitle(): string {
        return this.brand.object?.name ?? "Braendz Brand";
    }

    // Watcher
    @Watch('visible')
    public onVisibleChanged(visible: boolean): void {
        if(visible && !this.brand.isBusy && !this.brand.object) {
            this.loadBrand();
        }
    }

    // Methods:
    public async loadBrand() {
        await this.brand.load(async () => {
            return await BrandsService.getBrand(this.id);
        });
    }

    public close(): void {
        this.visible = false;
    }
}
