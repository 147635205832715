
































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import LocaleToolbarItem from './LocaleToolbarItem.vue';
import NotificationsToolbarItem from './NotificationsToolbarItem.vue';
import AccountToolbarItem from './AccountToolbarItem.vue';
import { AccountInfo } from "@azure/msal-browser";

import FeatureBadge from '@/components/Features/FeatureBadge.vue';

@Component({ 
  components: { 
    LocaleToolbarItem,
    NotificationsToolbarItem,
    AccountToolbarItem,
    FeatureBadge
  }
})
export default class AppBar extends Vue {
  // Member:
  public drawer = false;
  public isOnTop = true;
  public transparentOnTopRoutes = ['Home'];

  // Getter / Setter:
  public get userAccount(): AccountInfo | null {
    return this.$store.state.userAccount;
  }

  public get isTransparent(): boolean {
    return this.transparentOnTopRoutes.includes(this.$route.name as string) && this.isOnTop;
  }

  public get isTransparentOnTopRoute(): boolean {
    return this.transparentOnTopRoutes.includes(this.$route.name as string);
  }

  // Event Handler:
  onScroll(e: Event) {
    if (e.target instanceof Document && e.target?.scrollingElement?.scrollTop !== undefined) {
      this.isOnTop = e.target.scrollingElement.scrollTop === 0;
    }
  }
}
