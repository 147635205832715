/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandIndexItem } from '../models/BrandIndexItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BrandIndexService {

    /**
     * Gets the brand index item data.
     * @param id The brand index item id.
     * @returns BrandIndexItem Success
     * @throws ApiError
     */
    public static getIndexItem(
id: string,
): CancelablePromise<BrandIndexItem> {
        return __request({
            method: 'GET',
            path: `/api/v1/BrandIndex/${id}`,
            errors: {
                404: `Not Found`,
            },
        });
    }

}