/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterDataItem } from '../models/MasterDataItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ViennaClassesService {

    /**
     * Gets all known vienna classes and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getViennaClasses(): CancelablePromise<Array<MasterDataItem>> {
        return __request({
            method: 'GET',
            path: `/api/v1/ViennaClasses`,
        });
    }

    /**
     * Adds or updates a vienna class in the data storage.
     * @param requestBody The new or existing vienna class.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static upsertViennaClass(
requestBody?: MasterDataItem,
): CancelablePromise<MasterDataItem> {
        return __request({
            method: 'PUT',
            path: `/api/v1/ViennaClasses`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets the vienna class by its code and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The key.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getViennaClassByKey(
key: string,
): CancelablePromise<MasterDataItem> {
        return __request({
            method: 'GET',
            path: `/api/v1/ViennaClasses/${key}`,
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Deletes the vienna class by its code.
     * @param key The key.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteViennaClass(
key: string,
): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/v1/ViennaClasses/${key}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}