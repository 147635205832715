var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ShareNetwork",
    {
      attrs: {
        network: _vm.network,
        url: _vm.url,
        title: _vm.title,
        description: _vm.description,
        quote: _vm.quote,
        hashtags: _vm.hashtags,
      },
    },
    [
      _vm.type === "button"
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  fab: "",
                                  bottom: "",
                                  color: _vm.color,
                                  small: "",
                                  light: _vm.light,
                                  dark: _vm.dark,
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm.icon
                              ? _c("v-icon", [_vm._v(_vm._s(_vm.icon))])
                              : _vm.logo
                              ? _c("v-img", {
                                  attrs: {
                                    src: _vm.logo,
                                    contain: "",
                                    "max-height": "24",
                                    "max-width": "24",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2468477730
              ),
            },
            [_c("span", [_vm._v(_vm._s(_vm.label))])]
          )
        : _vm.type === "list-item"
        ? _c(
            "v-list-item",
            { attrs: { light: _vm.light, dark: _vm.dark } },
            [
              _c(
                "v-list-item-icon",
                [
                  _vm.icon
                    ? _c("v-icon", { attrs: { color: _vm.color } }, [
                        _vm._v(_vm._s(_vm.icon)),
                      ])
                    : _vm.logo
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.logo,
                          contain: "",
                          "max-height": "24",
                          "max-width": "24",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _vm.label
                    ? _c("v-list-item-title", [_vm._v(_vm._s(_vm.label))])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }