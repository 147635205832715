var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 640 512" },
    },
    [
      _c("path", {
        attrs: {
          d: "M32 128l0 256c0 17.7 14.3 32 32 32c118.5 0 236.9 0 355.4 0c13.5 10.7 27 21.3 40.5 32c-132 0-264 0-395.9 0c-35.3 0-64-28.7-64-64c0-85.4 0-170.7 0-256c0-12.9 3.8-24.9 10.4-34.9c8.4 6.7 16.9 13.3 25.3 20c-2.3 4.5-3.7 9.5-3.7 14.9zM608 384l0-256c0-17.7-14.3-32-32-32c-118.5 0-236.9 0-355.4 0c-13.5-10.7-27-21.3-40.5-32c132 0 264 0 395.9 0c35.3 0 64 28.7 64 64c0 85.4 0 170.7 0 256c0 12.9-3.8 24.9-10.4 34.9c-8.4-6.7-16.9-13.3-25.3-20c2.3-4.5 3.7-9.5 3.7-14.9zM254.9 330.1c3.3 8.2-.7 17.5-8.9 20.8s-17.5-.7-20.8-8.9c-2.9-7.3-5.9-14.7-8.8-22c-.1 0-.3 0-.4 0c-26.7 0-53.3 0-80 0c-.1 0-.3 0-.4 0c-2.9 7.3-5.9 14.6-8.8 21.9c-3.3 8.2-12.6 12.2-20.8 8.9s-12.2-12.6-8.9-20.8c17.1-42.7 34.1-85.3 51.2-128c8.7 6.9 17.5 13.8 26.2 20.7c-8.7 21.8-17.4 43.5-26.2 65.3c18.4 0 36.7 0 55.1 0c-8.2-20.7-16.5-41.3-24.7-62c16.8 13.3 33.6 26.5 50.4 39.8c8.6 21.4 17.1 42.8 25.7 64.2zM304 160l56 0c30.9 0 56 25.1 56 56c0 10.3-2.7 19.9-7.6 28.2c-8.6-6.8-17.1-13.5-25.7-20.3c.9-2.5 1.3-5.2 1.3-7.9c0-13.3-10.7-24-24-24c-5.9 0-11.9 0-17.8 0c-13.5-10.6-26.9-21.2-40.4-31.9c.7-.1 1.4-.1 2.1-.1zm0 192c-8.8 0-16-7.2-16-16c0-5.3 0-10.7 0-16c0-2.6 0-5.1 0-7.7c16.8 13.2 33.5 26.5 50.3 39.7c-11.4 0-22.9 0-34.3 0zM3.4 6.1C8.9-.8 19-2 25.9 3.4c202.7 160 405.3 320 608 480c7 5.5 8.1 14.7 2.7 22.5c-5.5 7-15.6 8.1-22.5 2.7c-202.7-160-405.4-320-608-480C-.8 23.1-2 13 3.4 6.1z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }