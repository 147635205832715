var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      ref: "tooltip",
      attrs: {
        disabled: _vm.disabled,
        top: _vm.top,
        bottom: _vm.bottom,
        left: _vm.left,
        right: _vm.right,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attr = ref.attr
              return [
                _c(
                  "div",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "overflow-hidden",
                        on: { mouseover: _vm.onHover },
                      },
                      "div",
                      attr,
                      false
                    ),
                    on
                  ),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [_c("span", [_vm._v(_vm._s(_vm.label))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }