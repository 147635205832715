/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandImagesService {
    /**
     * Gets the brand image by its path.
     * @param path The path of the image.
     * @param base64Encoded Specifies if the path is base64 encoded.
     * @returns any Success
     * @throws ApiError
     */
    public static getBrandImage(
        path: string,
        base64Encoded?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/BrandImages/{path}',
            path: {
                'path': path,
            },
            query: {
                'base64Encoded': base64Encoded,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
}
