









































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import AppBar from '@/components/AppBar/AppBar.vue';
import NotificationBar from '@/components/NotificationBar.vue';
import CookieBanner from '@/components/Cookies/CookieBanner.vue';
import Footer from '@/components/Footer.vue';
import FeedbackSnackbar from '@/components/Feedback/FeedbackSnackbar.vue';

@Component({
  components: {
    AppBar,
    NotificationBar,
    CookieBanner,
    FeedbackSnackbar,
    Footer
  }
})
export default class App extends Vue {
  
  // Getter and Setter
  public get isLoadingApp(): boolean {
    return false
      || this.$store.state.user.isBusy 
      || this.$store.state.permissions.isBusy 
      || this.$store.state.account.isBusy 
      || this.$store.state.activeSubscription.isBusy
      || this.$store.state.registrationOfficeCodes.isBusy
      || this.$store.state.brandTypes.isBusy
      || this.$store.state.brandStates.isBusy
      || this.$store.state.brandStateCategories.isBusy
      || this.$store.state.niceClasses.isBusy
      || this.$store.state.products.isBusy
      || this.$store.state.packages.isBusy;
  }

  // Lifecycle Hooks
  public mounted() {
    this.$store.dispatch("updateProductsAndPackages");
  }
}

