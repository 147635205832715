

























































































































































































































































import { Subscription, SubscriptionsService } from '@/api/braendz';
import { BusyObject } from '@/models/Busy';
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import SubscriptionState from '@/components/Account/SubscriptionState.vue'
import SubscriptionDetailsTable from '@/components/Account/SubscriptionDetailsTable.vue'
import ConfirmationPopup from '@/components/Popups/ConfirmationPopup.vue'

@Component({
    components: {
        SubscriptionDetailsTable,
        SubscriptionState,
        ConfirmationPopup
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class SubscriptionPopup extends Vue {

    // Properties
    @Prop({ required: true })
    public model!: boolean;

    public showUnsubscribeConfirmation = false;
    public unsubscribing = false;

    // Getter & Setter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }

    public get activeSubscription(): BusyObject<Subscription> {
        return this.$store.state.activeSubscription;
    }

    // Watcher

    // Lifecycle Methods:

    // Methods:
    public close(): void {
        this.visible = false;
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }

    public async unsubscribe(): Promise<void> {
        await this.activeSubscription.update(async () => {
            try {
                if(this.activeSubscription.object?.payPalSubscription?.id) {
                    this.unsubscribing = true;
                    await SubscriptionsService.cancelPayPalSubscription(this.activeSubscription.object.payPalSubscription.id, { 
                        reason: this.$i18n.t('subscriptionPopup.cancellationReason').toString() 
                    });
                    return await SubscriptionsService.getActiveSubscriptionCurrentUserDefaultAccount();
                }
                else {
                    throw new Error('PayPal subscription not found.');
                }
            }
            finally {
                this.unsubscribing = false;
            }
        });
    }

    public upgrade(): void {
        this.close();
        this.$router.push({name: 'Pricing'});
    }
}
