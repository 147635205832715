var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { persistent: "", "max-width": "700" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", dark: "" } },
                    [
                      _vm.$slots.title
                        ? _c("v-toolbar-title", [_vm._t("title")], 2)
                        : _c(
                            "v-toolbar-title",
                            [
                              _vm.icon
                                ? _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(_vm._s(_vm.icon)),
                                  ])
                                : _vm.question
                                ? _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-circle-question"),
                                  ])
                                : _vm.exclamation
                                ? _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-circle-exclamation"),
                                  ])
                                : _vm.error
                                ? _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-octagon-xmark"),
                                  ])
                                : _vm.warning
                                ? _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-circle-info"),
                                  ])
                                : _vm.info
                                ? _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-circle-info"),
                                  ])
                                : _vm._e(),
                              _vm.title
                                ? _c("span", [_vm._v(_vm._s(_vm.title))])
                                : _vm._e(),
                            ],
                            1
                          ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", disabled: !_vm.cancel, dark: "" },
                          on: { click: _vm.onCancel },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.$slots.text
                    ? _c("v-card-text", [_vm._t("text")], 2)
                    : _vm.text
                    ? _c("v-card-text", { staticClass: "pt-4 pb-0" }, [
                        _vm._v(" " + _vm._s(_vm.text) + " "),
                      ])
                    : _vm._e(),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm.yes
                        ? _c(
                            "v-btn",
                            { attrs: { plain: "" }, on: { click: _vm.onYes } },
                            [_vm._v("Yes")]
                          )
                        : _vm._e(),
                      _vm.no
                        ? _c(
                            "v-btn",
                            { attrs: { plain: "" }, on: { click: _vm.onNo } },
                            [_vm._v("No")]
                          )
                        : _vm._e(),
                      _vm.ok
                        ? _c(
                            "v-btn",
                            { attrs: { plain: "" }, on: { click: _vm.onOk } },
                            [_vm._v("OK")]
                          )
                        : _vm._e(),
                      _vm.cancel
                        ? _c(
                            "v-btn",
                            {
                              attrs: { plain: "" },
                              on: { click: _vm.onCancel },
                            },
                            [_vm._v("Cancel")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }