var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "", flat: "", color: "transparent", width: _vm.width } },
    [
      _vm.loginRequired && !_vm.userAccount
        ? [
            _c(
              "v-btn",
              {
                attrs: { plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.$auth.signIn()
                  },
                },
              },
              [
                _c("v-icon", { attrs: { left: "" } }, [
                  _vm._v("fa-light fa-right-to-bracket"),
                ]),
                _vm._v(
                  " " + _vm._s(_vm.$t("brand.details.loginMessage")) + " "
                ),
              ],
              1
            ),
          ]
        : _vm.value
        ? _vm._l(
            _vm.$braendz.getAddressLines(_vm.value),
            function (line, index) {
              return _c(
                "span",
                { key: index, staticClass: "d-block text-truncate body-1" },
                [_vm._v(" " + _vm._s(line) + " ")]
              )
            }
          )
        : _c(
            "span",
            { staticClass: "d-block text-truncate body-1 text--disabled" },
            [_vm._v(_vm._s(_vm.$t("brand.details.notAvailable")))]
          ),
      _c("span", { staticClass: "d-block caption" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }