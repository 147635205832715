var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    _vm._b(
      {
        attrs: { timeout: _vm.timeout, color: _vm.color, top: "" },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  _vm._b(
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    "v-btn",
                    _vm.$attrs,
                    false
                  ),
                  [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      },
      "v-snackbar",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-layout",
        _vm._b(
          { attrs: { "align-center": "", "pr-4": "" } },
          "v-layout",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "v-icon",
            _vm._b(
              { staticClass: "pr-3", attrs: { dark: "", large: "" } },
              "v-icon",
              _vm.$attrs,
              false
            ),
            [_vm._v(_vm._s(_vm.icon))]
          ),
          _c("v-layout", { attrs: { column: "" } }, [
            _c("div", [_vm._v(_vm._s(_vm.text))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }