var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { staticClass: "d-flex flex-column", attrs: { dark: "", padless: "" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between pa-3" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column align-start",
                  attrs: { cols: "auto" },
                },
                [
                  _c("v-img", {
                    staticClass: "shrink",
                    attrs: {
                      alt: "Braendz Logo",
                      src: require("@/assets/logos/braendz-light.svg"),
                      width: "100",
                    },
                  }),
                  _c("span", {
                    staticClass: "d-block body-2 py-1",
                    staticStyle: { color: "hsla(0,0%,100%,.7)" },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("footer.serviceOfConxepta")),
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0",
                      attrs: {
                        ripple: false,
                        plain: "",
                        href: "http://www.conxepta.com",
                      },
                    },
                    [_vm._v("conxepta.com")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column align-start",
                  attrs: { cols: "auto" },
                },
                [
                  _c("h3", { staticClass: "overline" }, [
                    _vm._v(" " + _vm._s(_vm.$t("footer.legalHeadline")) + " "),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0",
                      attrs: {
                        small: "",
                        ripple: false,
                        plain: "",
                        to: { name: "Imprint" },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", "x-small": "" } }, [
                        _vm._v("fa-light fa-chevron-right"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("footer.linkImprint")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0",
                      attrs: {
                        small: "",
                        ripple: false,
                        plain: "",
                        to: { name: "Privacy" },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", "x-small": "" } }, [
                        _vm._v("fa-light fa-chevron-right"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("footer.linkPrivacy")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0",
                      attrs: {
                        small: "",
                        ripple: false,
                        plain: "",
                        to: { name: "Terms" },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", "x-small": "" } }, [
                        _vm._v("fa-light fa-chevron-right"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("footer.linkTerms")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("h3", { staticClass: "overline" }, [
                    _vm._v(" Let's connect! "),
                  ]),
                  _c(
                    "v-layout",
                    { staticStyle: { "margin-left": "-12px" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-large": "",
                            icon: "",
                            color: "#3A66C3 !important",
                            href: "https://www.linkedin.com/company/braendzcom",
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-brands fa-linkedin")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-large": "",
                            icon: "",
                            color: "#E7E9EA !important",
                            href: "https://x.com/braendz_com",
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-brands fa-x-twitter")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-large": "",
                            icon: "",
                            color: "#3b5998 !important",
                            href: "https://www.facebook.com/braendzpro",
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-brands fa-facebook")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-large": "",
                            icon: "",
                            color: "#006464 !important",
                            href: "https://www.xing.com/companies/conxeptagmbh",
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-brands fa-xing")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { attrs: { width: "100%" } }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center body-2 py-2" }, [
                _vm._v(
                  " Copyright © " + _vm._s(new Date().getFullYear()) + " — "
                ),
                _c("strong", [_vm._v("CONXEPTA GmbH")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }