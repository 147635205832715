var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Popup", {
    attrs: {
      persistant: "",
      title: _vm.title,
      icon: _vm.icon,
      "max-width": "700",
    },
    on: { closed: _vm.onCancel },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _vm.$slots.text
                ? _c("v-card-text", [_vm._t("text")], 2)
                : _vm.text
                ? _c("v-card-text", { staticClass: "pt-4 pb-0" }, [
                    _vm._v(" " + _vm._s(_vm.text) + " "),
                  ])
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm.yes
                    ? _c(
                        "v-btn",
                        { attrs: { plain: "" }, on: { click: _vm.onYes } },
                        [_vm._v(_vm._s(_vm.$t("confirmationPopup.yes")))]
                      )
                    : _vm._e(),
                  _vm.no
                    ? _c(
                        "v-btn",
                        { attrs: { plain: "" }, on: { click: _vm.onNo } },
                        [_vm._v(_vm._s(_vm.$t("confirmationPopup.no")))]
                      )
                    : _vm._e(),
                  _vm.ok
                    ? _c(
                        "v-btn",
                        { attrs: { plain: "" }, on: { click: _vm.onOk } },
                        [_vm._v(_vm._s(_vm.$t("confirmationPopup.ok")))]
                      )
                    : _vm._e(),
                  _vm.cancel
                    ? _c(
                        "v-btn",
                        { attrs: { plain: "" }, on: { click: _vm.onCancel } },
                        [_vm._v(_vm._s(_vm.$t("confirmationPopup.cancel")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }