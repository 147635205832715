var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialogOpenend,
        callback: function ($$v) {
          _vm.dialogOpenend = $$v
        },
        expression: "dialogOpenend",
      },
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c(
              "v-toolbar",
              { attrs: { dark: "", dense: "" } },
              [
                _c("v-toolbar-title", [
                  _vm._v(_vm._s(_vm.$t("cookie.settingsDialog.dialogTitle"))),
                ]),
              ],
              1
            ),
            _c(
              "v-card-text",
              [
                _c(
                  "v-list",
                  {
                    attrs: {
                      flat: "",
                      color: "transparent",
                      width: "unset",
                      "three-line": "",
                    },
                  },
                  _vm._l(_vm.cookieCategories, function (category) {
                    return _c("v-list-item", {
                      key: category.key,
                      attrs: { disabled: !category.arbitrable },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var active = ref.active
                              return [
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "input-value": active,
                                        disabled: !category.arbitrable,
                                      },
                                      model: {
                                        value: category.isActive,
                                        callback: function ($$v) {
                                          _vm.$set(category, "isActive", $$v)
                                        },
                                        expression: "category.isActive",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(category.text)),
                                    ]),
                                    _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "wrap-text" },
                                      [_vm._v(_vm._s(category.description))]
                                    ),
                                    category.cookies.length > 0 &&
                                    _vm.$vuetify.breakpoint.mdAndUp
                                      ? _c("v-simple-table", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function () {
                                                  return [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "cookie.settingsDialog.columnHeaderCookieName"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "cookie.settingsDialog.columnHeaderCookieDomain"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "cookie.settingsDialog.columnHeaderCookieDescription"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        category.cookies,
                                                        function (cookie) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key: cookie.name,
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    cookie.name
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    cookie.domain
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    cookie.description
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card-actions",
              [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "ma-4",
                    attrs: { plain: "", width: "200" },
                    on: { click: _vm.accept },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v(" fa-light fa-floppy-disk "),
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm.$t("cookie.settingsDialog.save")) + " "
                    ),
                  ],
                  1
                ),
                _c("v-spacer"),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }