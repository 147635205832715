var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        class: {
          "scroll-btn": true,
          absolute: _vm.position === "absolute",
          fixed: _vm.position === "fixed",
          dark: _vm.dark,
          light: !_vm.dark,
        },
      },
      "div",
      _vm.$attrs,
      false
    ),
    [
      _vm.to
        ? _c("router-link", { attrs: { to: _vm.to } }, [
            _c("i", { staticClass: "fa fa-angle-down animated icon" }),
          ])
        : _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onClicked.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-angle-down animated icon" })]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }