/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterDataItem } from '../models/MasterDataItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class KindMarksService {
    /**
     * Gets all known kind marks and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getKindMarks(): CancelablePromise<Array<MasterDataItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/KindMarks',
        });
    }
    /**
     * Adds or updates a kind mark in the data storage.
     * @param requestBody The new or existing kind mark.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static upsertKindMark(
        requestBody?: MasterDataItem,
    ): CancelablePromise<MasterDataItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/KindMarks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Gets the kind mark by its key and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The key.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getKindMarkByKey(
        key: string,
    ): CancelablePromise<MasterDataItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/KindMarks/{key}',
            path: {
                'key': key,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Deletes the kind mark by its key.
     * @param key The key.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteKindMark(
        key: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/KindMarks/{key}',
            path: {
                'key': key,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
