var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: _vm.displayColumn } },
    [
      _c(
        "v-col",
        {
          class: {
            "d-flex": true,
            "justify-start": _vm.horizontalOrientationFirst === "left",
            "justify-center": _vm.horizontalOrientationFirst === "center",
            "justify-end": _vm.horizontalOrientationFirst === "right",
          },
          style: {
            "padding-left": _vm.sidePadding,
            "padding-right": _vm.displayColumn
              ? _vm.sidePadding
              : _vm.midPadding,
            "padding-top": _vm.yPadding,
            "padding-bottom": _vm.yPadding,
            width:
              _vm.horizontalOrientationFirst === "stretch" ? "100%" : undefined,
          },
          attrs: {
            order: _vm.displayColumn
              ? _vm.orderOnBreak === "right-first"
                ? 1
                : 0
              : undefined,
            cols: _vm.displayColumn ? 12 : 6,
          },
        },
        [
          _c(
            "div",
            {
              class: {
                "align-self-start": _vm.verticalOrientationFirst === "top",
                "align-self-center": _vm.verticalOrientationFirst === "center",
                "align-self-end": _vm.verticalOrientationFirst === "bottom",
                "align-self-stretch":
                  _vm.verticalOrientationFirst === "stretch",
              },
              style: {
                width:
                  _vm.horizontalOrientationFirst === "stretch"
                    ? "100%"
                    : undefined,
              },
            },
            [_vm._t("first")],
            2
          ),
        ]
      ),
      _c(
        "v-col",
        {
          class: {
            "d-flex": true,
            "justify-start": _vm.horizontalOrientationSecond === "left",
            "justify-center": _vm.horizontalOrientationSecond === "center",
            "justify-end": _vm.horizontalOrientationSecond === "right",
          },
          style: {
            "padding-left": _vm.displayColumn
              ? _vm.sidePadding
              : _vm.midPadding,
            "padding-right": _vm.sidePadding,
            "padding-top": _vm.yPadding,
            "padding-bottom": _vm.yPadding,
            width:
              _vm.horizontalOrientationFirst === "stretch" ? "100%" : undefined,
          },
          attrs: {
            order: _vm.displayColumn
              ? _vm.orderOnBreak === "right-first"
                ? 0
                : 1
              : undefined,
            cols: _vm.displayColumn ? 12 : 6,
          },
        },
        [
          _c(
            "div",
            {
              class: {
                "align-self-start": _vm.verticalOrientationSecond === "top",
                "align-self-center": _vm.verticalOrientationSecond === "center",
                "align-self-end": _vm.verticalOrientationSecond === "bottom",
                "align-self-stretch":
                  _vm.verticalOrientationSecond === "stretch",
              },
              style: {
                width:
                  _vm.horizontalOrientationSecond === "stretch"
                    ? "100%"
                    : undefined,
              },
            },
            [_vm._t("second")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }