import _Vue from "vue";

export class Env {
    // public featureSubscriptionActive(): boolean {
    //     return process.env.VUE_APP_FEATURE_SUBSCRIPTIONS_ACTIVE === "true";
    // }
}

export const env = new Env();

export function EnvPlugin(Vue: typeof _Vue): void {
    Vue.prototype.$env = env;
}

declare module 'vue/types/vue' {
    interface Vue {
        $env: Env;
    }
}