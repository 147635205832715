var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 687 597",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M96,112c0,26.5,21.5,48,48,48c26.5,0,48-21.5,48-48s-21.5-48-48-48C117.5,64,96,85.5,96,112z M160,112c0,8.8-7.2,16-16,16\n        s-16-7.2-16-16s7.2-16,16-16S160,103.2,160,112z",
          },
        }),
        _c("path", {
          attrs: {
            d: "M184.3,416h-33.7l33.7-33.6v-45.2L105.4,416H64c-17.7,0-32-14.3-32-32v-41.4l90.4-90.3c3.1-3.1,8.2-3.1,11.3,0l50.6,50.6\n        V279c0-6.5,0.8-12.9,2.4-19l-30.3-30.3c-15.7-15.6-41-15.6-56.6,0L32,297.4V64c0-17.7,14.3-32,32-32h384c17.7,0,32,14.3,32,32v138\n        h32V64c0-35.3-28.7-64-64-64H64C28.7,0,0,28.7,0,64v320c0,35.3,28.7,64,64,64h120.3V416z",
          },
        }),
        _c("path", {
          attrs: {
            d: "M412.3,165.7c-15.7-15.6-41-15.6-56.6,0L319.4,202h45.3l13.7-13.7c3.1-3.1,8.2-3.1,11.3,0l13.7,13.7h45.2L412.3,165.7z",
          },
        }),
      ]),
      _c("g", [
        _c("path", {
          attrs: {
            d: "M371.4,313.6c-2.5-7-8.4-10.1-14.9-10.1s-12.4,3.1-14.9,10.1l-64,160c-3.3,8.2,0.7,17.5,9,20.8c8.2,3.2,17.5-0.8,20.8-9\n        l8.7-22.8l0.4,0.9h80l0.4-0.9l8.7,22.8c3.3,8.2,12.6,12.2,20.8,9c8.2-3.3,12.2-12.6,9-20.8L371.4,313.6z M328.9,431.5l27.6-68.9\n        l27.6,68.9H328.9z",
          },
        }),
        _c("path", {
          attrs: {
            d: "M586.1,392c6.6-9.2,10.4-20.4,10.4-32.5c0-30.9-25.1-56-56-56h-56c-8.8,0-16,7.2-16,16v160c0,8.8,7.2,16,16,16h72\n        c30.9,0,56-25.1,56-56C612.5,418.6,602,401.9,586.1,392z M500.5,335.5h40c13.3,0,24,10.7,24,24s-10.7,24-24,24h-40V335.5z\n        M556.5,463.5h-56v-48h56c13.3,0,24,10.7,24,24S569.8,463.5,556.5,463.5z",
          },
        }),
      ]),
      _c("path", {
        staticClass: "storke",
        staticStyle: {
          fill: "none",
          "stroke-width": "32",
          "stroke-miterlimit": "10",
        },
        attrs: {
          d: "M629.8,580H256.2c-22.1,0-40.2-18.1-40.2-40.2V270.2c0-22.1,18.1-40.2,40.2-40.2h373.5\n    c22.1,0,40.2,18.1,40.2,40.2v269.5C670,561.9,651.9,580,629.8,580z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }